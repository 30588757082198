<!-- 收货地址 -->
<template>
  <div class="app-wrapper">
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <div class="page-header">
          <i class="page-header-title" style="margin-right: 16px"></i>收货地址
        </div>
      </div>

      <div style="padding: 10px 0px">
        <el-button type="primary" @click="insterAddress()" size="medium">
          添加地址
        </el-button>
      </div>

      <el-table :data="listData" style="width: 100%" border>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>

        <el-table-column prop="tel" label="联系电话" align="center">
        </el-table-column>

        <el-table-column prop="address" label="所在地区" align="center">
          <template slot-scope="scope">
            {{ scope.row.regionProvinceName }} /
            {{ scope.row.regionCityName }} / {{ scope.row.regionCountryName }}
          </template>
        </el-table-column>

        <el-table-column prop="address" label="详细地址" align="center">
        </el-table-column>

        <el-table-column prop="operation" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="updateRow(scope.row)" type="text">
              编辑
            </el-button>

            <el-button @click="deleteRow(scope.row)" type="text">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加收货地址 -->
    <el-dialog
      title="收货地址"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
    >
      <el-card shadow="never">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="top"
        >
          <el-form-item label="姓名" prop="deliveryPerson">
            <el-input
              v-model="ruleForm.deliveryPerson"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>

          <el-form-item label="联系电话" prop="deliveryPhone">
            <el-input
              v-model="ruleForm.deliveryPhone"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>

          <el-form-item label="所在地区" prop="productId">
            <el-cascader
              v-model="fieldValue"
              :props="{
                value: 'id',
                label: 'regionName',
                children: 'children',
              }"
              :options="treeList"
              @change="handleChange"
            >
              <template slot-scope="{ node, data }">
                <span>{{ data.regionName }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>

          <el-form-item label="详细地址" prop="deliveryAddress">
            <el-input
              v-model="ruleForm.deliveryAddress"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">
              确认
            </el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAddressList,
  regionTree,
  address,
  deleteRess,
  updateRess,
} from "@/api/useraddress";
import { Toast } from "vant";

export default {
  data() {
    return {
      rules: {
        deliveryPerson: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        deliveryPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        deliveryAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      fieldValue: undefined,

      ruleForm: {
        deliveryPerson: undefined,
        deliveryPhone: undefined,
        deliveryAddress: undefined,

        //省
        regionProvince: undefined,
        regionProvinceName: undefined,

        regionCity: undefined,
        regionCityName: undefined,

        regionCountry: undefined,
        regionCountryName: undefined,
      },
      dialogVisible: false,

      listData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      treeList: [],
    };
  },
  created() {
    //查询收货地址
    this.loadData();

    //查询收货地址
    this.getRegionTree();
  },
  methods: {
    loadData() {
      new Promise((resolve, rejust) => {
        getAddressList(this.queryParam)
          .then((res) => {
            this.listData = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            new Promise((resolve, rejust) => {
              updateRess(this.ruleForm)
                .then((res) => {
                  console.log(res);
                  if (res.status == 0) {
                    Toast.success({
                      message: "修改成功",
                    });
                    this.dialogVisible = false;
                    this.loadData();
                  }
                  resolve();
                })
                .catch((error) => {
                  rejust(error);
                });
            });
          } else {
            new Promise((resolve, rejust) => {
              address(this.ruleForm)
                .then((res) => {
                  console.log(res);
                  if (res.status == 0) {
                    Toast.success({
                      message: "添加成功",
                    });
                    this.dialogVisible = false;
                    this.loadData();
                  }
                  resolve();
                })
                .catch((error) => {
                  rejust(error);
                });
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * 新增收货地址
     */
    insterAddress() {
      this.dialogVisible = true;

      this.fieldValue = undefined;

      this.ruleForm.deliveryPerson = undefined;
      this.ruleForm.deliveryPhone = undefined;
      this.ruleForm.deliveryAddress = undefined;

      //省
      this.ruleForm.regionProvince = undefined;
      this.ruleForm.regionProvinceName = undefined;

      this.ruleForm.regionCity = undefined;
      this.ruleForm.regionCityName = undefined;

      this.ruleForm.regionCountry = undefined;
      this.ruleForm.regionCountryName = undefined;
    },

    /**
     * 修改收货地址
     */
    updateRow(row) {
      this.ruleForm.id = row.id;

      this.ruleForm.deliveryPerson = row.name;
      this.ruleForm.deliveryPhone = row.tel;
      this.ruleForm.deliveryAddress = row.address;

      this.dialogVisible = true;
    },

    /**
     * 删除收货地址
     */
    deleteRow(data) {
      let id = {
        id: data.id,
      };
      new Promise((resolve, rejust) => {
        deleteRess(id)
          .then((res) => {
            console.log(res);
            if (res.status == 0) {
              Toast.success({
                message: "删除成功",
              });
              this.loadData();
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    getRegionTree() {
      new Promise((resolve, rejust) => {
        regionTree({})
          .then((res) => {
            this.treeList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 新增收货地址
     */
    onSubmit() {
      new Promise((resolve, rejust) => {
        address(this.ruleForm)
          .then((res) => {
            console.log(res);
            if (res.status == 0) {
              Toast.success({
                message: "添加成功",
              });
              this.dialogVisible = false;
              this.loadData();
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    onAdd() {
      this.dialogVisible = true;
    },

    //选择地区
    handleChange(selectedOptions) {
      //省
      this.ruleForm.regionProvince = selectedOptions[0];

      this.ruleForm.regionCity = selectedOptions[1];

      this.ruleForm.regionCountry = selectedOptions[2];
    },
  },
};
</script>
