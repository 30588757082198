<template>
  <div class="app-container">
    <el-card shadow="never">
      <el-table :data="mealList" style="width: 100%" border>
        <el-table-column prop="mealName" label="分站套餐" align="center">
        </el-table-column>
        <el-table-column prop="mealPrice" label="套餐价格" align="center">
        </el-table-column>

        <el-table-column prop="markupPrice" label="加价" align="center">
        </el-table-column>

        <el-table-column prop="operation" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="editItem(scope.row)" type="text">
              设置价格
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 设置价格弹框 -->
    <el-dialog
      title="加价幅度"
      :visible.sync="dialogVisible"
      center
      append-to-body
      class="el_dialog_radius"
    >
      <div style="text-align: center; margin-top: 20px">
        <el-form label-position="top">
          <el-form-item>
            <!-- <el-input
              v-model="mealFrom.price"
              placeholder="增加或者减少的金额/百分比"
            ></el-input> -->

            <el-input-number
              v-model="mealFrom.price"
              :min="1"
              :max="9999999"
              label="加价金额"
              width="100%"
            ></el-input-number>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer" style="margin-top: 20px">
          <el-button type="primary" @click="subimtInster">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getVipMealList, insterMealprice } from "@/api/vip";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      dialogVisible: false,
      mealList: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
      mealFrom: {
        mealId: undefined,
        price: 1,
      },
    };
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.initData();
      }
    },

    initData() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        getVipMealList(this.queryParam)
          .then((res) => {
            //主站
            this.mealList = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 设置价格
     */
    editItem(row) {
      this.mealFrom.mealId = row.id;
      this.dialogVisible = true;
    },

    /**
     * 新增
     */
    subimtInster() {
      new Promise((resolve, rejust) => {
        insterMealprice(this.mealFrom)
          .then((res) => {
            console.log(res);
            this.$message({
              message: "设置成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.initData();
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },

    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },
  },
};
</script>
