<template>
  <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
    },
    { 'border-radius': module.base.round + 'px' },
  ]">
    <div style="    background-color: #FFFFFF;">
      <!-- 导航 -->
      <van-grid :column-num="module.max" :border="false" class="menu_class">
        <van-grid-item v-for="(item, index) in module.list" :key="index">
          <a :href="item.link" target="_blank">
            <van-badge :content="item.mentuBadge">
              <div style="text-align: center">
                <van-image round :src="item.imageUrl" style="width: 42px; height: 42px; border-radius: 5px" />

                <!-- 自定义文字 -->
                <div style="font-size: 12px; color: #303133; text-align: center">
                  {{ item.name }}
                </div>
              </div>
            </van-badge>
          </a>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      menuList: [],
    };
  },

  methods: {

  },
};
</script>

<style>
.classify_view {
  /* width: 347px; */
  /* height: 334rpx; */
  background-color: #fff;
}

.menu_class .van-grid-item__content{
  padding: 8px 8px;
}
</style>
