var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
   !_vm.module.base.m ? { 'margin-top': '0' } : {},
   {
     padding:
       '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
   },
 ])},[_c('div',[_c('div',{staticClass:"gds-list3"},[_c('van-tabs',{attrs:{"offset-top":50,"ellipsis":false},on:{"":function($event){}}},_vm._l((_vm.catesList),function(item){return _c('van-tab',{key:item.id,attrs:{"title":item.categoryName,"name":item.id}},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex":"1","height":"calc(100%)"}},[_c('div',{staticStyle:{"background-color":"#ffffff","font-size":"14px","margin-right":"6px"}},[_c('van-sidebar',{on:{"change":_vm.onChange},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},_vm._l((item.children),function(value){return _c('van-sidebar-item',{key:value.id,staticStyle:{"background-color":"#ffffff"},attrs:{"title":value.categoryName,"name":value.id},on:{"click":function($event){return _vm.toSecondPCate(value)}}})}),1)],1),_c('div',{staticStyle:{"box-sizing":"border-box","height":"100%","overflow":"auto","flex":"1"}},[_c('van-list',[_c('div',[_c('div',[_c('div',{staticClass:"kind-show goods-list"},[_c('div',{staticClass:"goods-content"},_vm._l((_vm.listData),function(v,k,i){return _c('div',{key:i,staticClass:"goods-item",attrs:{"title":v.productName},on:{"click":function($event){return _vm.toProductDetails(v)}}},[_c('div',{staticClass:"goods-img"},[_c('van-image',{staticClass:"choice_img",attrs:{"src":v.productImg}})],1),_c('div',{staticClass:"product_centent"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(v.productName))]),_c('div',{staticClass:"fun"},[_c('span',{staticClass:"money"},[_c('span',[_c('span',{staticClass:"now-money"},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("￥"+_vm._s(v.productPrice))])])])])])])])}),0)])])])])],1)])])])}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }