var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
   !_vm.module.base.m ? { 'margin-top': '0' } : {},
   {
     padding:
       '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
   },
 ])},[_c('div',{staticClass:"choice_view"},[_c('div',{staticClass:"gds-list"},[_c('div',{staticStyle:{"background-color":"#ffffff"}},[_c('van-tabs',{attrs:{"ellipsis":false},on:{"click":_vm.toPCate}},_vm._l((_vm.catesList),function(item){return _c('van-tab',{key:item.id,attrs:{"title":item.categoryName,"name":item.id}},[_c('van-swipe',{attrs:{"loop":true,"indicator-color":"#2196F3"}},[_c('van-swipe-item',[_c('van-grid',{attrs:{"icon-size":"36px","border":false,"column-num":4}},[_c('van-grid-item',{attrs:{"text":"爱奇艺","icon":"photo-o"}}),_c('van-grid-item',{attrs:{"text":"爱奇艺","icon":"photo-o"}}),_c('van-grid-item',{attrs:{"text":"爱奇艺","icon":"photo-o"}}),_c('van-grid-item',{attrs:{"text":"爱奇艺","icon":"photo-o"}})],1)],1)],1)],1)}),1)],1),_c('div',[_c('van-list',{staticStyle:{"background-color":"#f5f7fa"},attrs:{"finished-text":"没有更多了"}},[_c('van-row',{attrs:{"gutter":"10"}},_vm._l((_vm.listData),function(value,key,index){return _c('van-col',{key:index,attrs:{"span":"12"}},[_c('div',{staticStyle:{"background":"#fff","padding-bottom":"10px","padding":"8px 8px 10px 8px","border-radius":"5px","margin-top":"10px"},on:{"click":function($event){return _vm.toProductDetails(value)}}},[_c('span',{staticClass:"pic-wrap"},[_c('div',{staticClass:"nut-image",staticStyle:{"padding":"1px"}},[_c('van-empty')],1)]),_c('div',[_c('span',{staticClass:"tit"},[_vm._v(" "+_vm._s(value.productName)+" ")])]),_c('div',{staticStyle:{"margin":"10px 0px"}}),_c('div',{staticClass:"dx2-goods-price"},[_c('div',[_c('span',[_vm._v("￥1.00")])]),_c('span',{staticStyle:{"border":"1px solid #e1c1c7","background-color":"#fef4f3","display":"inline-block","height":"26px","padding":"0 10px","line-height":"24px","font-size":"12px","color":"#d57081","border-radius":"20px"}},[_vm._v(" 立即购买 ")])])])])}),1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }