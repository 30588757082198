<template>
  <div>
    <a-collapse default-active-key="1" :bordered="false">
      <!-- 基础组件 -->
      <a-collapse-panel key="1" header="基础组件" :style="customStyle">
        <a-row :gutter="16">
          <div
            style="cursor: pointer"
            v-for="(item, index) in list"
            :key="index"
            @click="comClick(item, 0)"
            @dragend="dragend"
            @dragstart="dragstart(item, 0)"
            draggable="true"
          >
            <a-col :span="8">
              <div
                style="font-size: 12px; text-align: center; margin-bottom: 30px"
              >
                <a-icon
                  type="appstore"
                  theme="twoTone"
                  style="font-size: 18px"
                />
                <p style="margin-top: 5px">{{ item.name }}</p>
              </div>
            </a-col>
          </div>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <!-- class="diy-wrapper" -->
    <!-- <div style="height: 100%">
      <div class="left" style="background-color: #FFFFFF;">
        <div class="wrapper">
          <div>
            <div class="tips">
              基础组件
              <div class="iconfont iconxia"></div>
            </div>
            <div
              class="dragArea list-group"
              style="display: grid;grid-template-columns: repeat(3, 1fr);"
            >
              <div
                class="list-group-item"
                v-for="(item, index) in list"
                :key="index"
                @click="comClick(item, 0)"
                @dragend="dragend"
                @dragstart="dragstart(item, 0)"
                draggable="true"
              >
                <div style="font-size: 14px;">
                  <img src="../../../assets/diy.svg" class="div_left_icon" />

                  <p style="margin-top: 5px;">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import module from "@/views/template/mobile/module.js";
export default {
  name: "Left",
  data() {
    return {
      customStyle:
        "background: #FFFFFF;border-radius: 4px;margin-bottom: 15px;border: 0;overflow: hidden;padding: 5px;",
      id: 1,
      left: [
        { txt: "页面", icon: "el-icon-s-platform" },
        { txt: "模块", icon: "el-icon-menu" },
        { txt: "控件", icon: "el-icon-s-operation" },
      ],
      cur: 0,
      list: [], //模块
      control: [], //控件
      pageList: [], //页面
    };
  },

  mounted() {
    //页面组件
    this.list = module.pageData;
    console.log(this.list);
    this.list.forEach((res, i1) => {
      res.base.sc.forEach((r, i2) => {
        r.p = "s" + (i1 + 1) + "_" + (i2 + 1) + ".png";
      });
    });

    //控件
    this.control = module.controlData;
  },

  methods: {
    dragstart(item, style) {
      event.dataTransfer.setData("style", style);
      event.dataTransfer.setData("text", JSON.stringify(item));
      event.dataTransfer.effectAllowed = "copyMove";
      this.$emit("placeholder", true);
    },

    controlClick(type) {
      this.$emit("controlClick", type);
    },

    dragend() {
      this.$emit("placeholder", false);
    },

    //控件点击
    comClick(item, style) {
      let json = JSON.stringify(item);
      this.$emit("componentsClick", json, style);
    },

    selectcontrol(index) {
      this.$parent.pageList[this.$parent.pageIndex].controlList[
        index
      ].check = true;
    },

    //切换页面
    changePage(index) {
      this.id = this.pageList[index].id;
      this.$parent.changePage(this.id);
    },
  },
};
</script>
<style scoped>
.div_left_icon {
  margin: 0 auto;
  width: 38px;
  margin-top: 10px;
}
</style>
