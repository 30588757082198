<template>
  <div class="browse">
    <van-nav-bar title="我的浏览" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="goods-content" style="padding: 10px" v-if="this.collectList.length > 0">
      <div style="border-radius: 10px" class="goods-item" v-for="(v, k, i) in collectList" :key="i"
        :title="v.productName" @click="toProductDetails(v)">
        <div class="goods-img">
          <el-image style="background-position: 0% 0%; background-size: 100% 100%" :src="v.picUrl"></el-image>
        </div>

        <div class="product_centent">
          <span class="name">{{ v.productName }}</span>
          <div></div>

          <div class="fun">
            <span class="money">
              <span>
                <span class="now-money">
                  <span style="font-size: 16px">￥{{ v.price }}</span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <!-- 按钮 -->
        <!-- <div>
          <van-button
            type="danger"
            size="small"
            plain
            round
            style="padding: 0px 20px"
            @click="deleteCollecProduct(v)"
          >
            删除
          </van-button>
        </div> -->
      </div>
    </div>
    <van-empty v-else description="还没有浏览过商品~" />
  </div>
</template>

<script>
import { getBrowseProductList } from "@/api/product";

export default {
  components: {},
  data() {
    return {
      collectList: [],
    };
  },

  created() {
    //初始化
    this.loadData();
  },
  methods: {
    loadData() {
      var data = {};
      //开通分站
      new Promise((resolve, rejust) => {
        getBrowseProductList(data)
          .then((res) => {
            this.collectList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/product_detail/${v.id}`);
    },

    //跳转到我的页面
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>

<style>
.browse .goods-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
}

.browse .goods-content .goods-item {
  width: 100%;
  /* height: 6.25rem; */
  background: #ffffff;
  box-shadow: 0 0.125rem 0.1875rem #f5f7fa;
  border-radius: 0.3125rem;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  gap: 0.625rem;
  padding: 0.625rem;
}

.browse .goods-content .goods-item .goods-img {
  width: 5.625rem;
  height: 5.625rem;
  border-radius: 5px;
  overflow: hidden;
}

.browse .goods-content .goods-item .product_centent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 10px;
}

.browse .goods-content .goods-item .product_centent .name {
  color: #191e2e;
  font-weight: 400;
  /* font-size: 0.8125rem; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 14.375rem; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* height: 42px; */
}

.browse .goods-content .goods-item .product_centent .fun {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.browse .goods-content .goods-item .product_centent .fun .money {
  font-weight: 500;
  color: #f84d43;
  font-size: 1.125rem;
}

.browse .goods-content .goods-item .product_centent .fun .money .default-money {
  font-weight: 400;
  color: #d2d5dd;
  font-size: 0.6875rem;
  text-decoration: line-through;
  margin-left: 0.53125rem;
}
</style>
