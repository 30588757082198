<template>
  <div>
    <div v-for="(item, index) in orderParams" :key="index">
      <van-field
        v-if="item.type === '1'"
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      />

      <van-field
        v-else-if="item.type === '2'"
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="extractLink(item.value, index)"
            >提取链接</van-button
          >
        </template>
      </van-field>

      <van-field
        v-else-if="item.type === '4'"
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      >
        <template #input>
          <el-upload
            action="/api/upload/public_pic"
            :headers="headers"
            :on-success="UploadOnSuccess(item, index)"
            :before-upload="beforeAvatarUpload"
            :limit="100"
            :show-file-list="false"
          >
            <img
              v-if="item.value"
              :src="item.value"
              style="width: 80px; border-radius: 5px"
            />
            <el-button v-else size="small" type="primary">点击上传</el-button>
          </el-upload>
        </template>
      </van-field>

      <van-field
        v-else
        v-model="item.value"
        :name="item.name"
        :label="item.name"
        :placeholder="item.desc"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderParams"],
  data() {
    return {
      orderParamList: [],
    };
  },

  methods: {
    extractLink(value, index) {
      if (value === "") {
        this.$message({
          message: "请输入参数",
          type: "error",
        });
        return;
      }
      this.orderParams[index].value = this.$extractLink(value);
    },

    /**
     * 图片上传
     */
    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    },

    /**
     * 上传成功返回参数
     */
    UploadOnSuccess(res, index) {
      return (response) => {
        this.orderParams[index].value = response.url;

        this.orderParams[index].uploadfileId = response.id;
      };
    },
  },
};
</script>
