var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
  !_vm.module.base.m ? { 'margin-top': '0' } : {},
  {
    padding:
      '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px',
  },
])},[_c('div',[_c('van-notice-bar',{staticStyle:{"border-radius":"5px"},attrs:{"color":_vm.module.style.color,"background":_vm.module.style.bgcolor,"left-icon":"volume-o","scrollable":false}},[_c('van-swipe',{staticClass:"notice-swipe",attrs:{"vertical":"","autoplay":3000,"show-indicators":false}},_vm._l((_vm.listData),function(item,index){return _c('van-swipe-item',{key:index,on:{"click":function($event){return _vm.toNoticeDetails(item)}}},[_vm._v(_vm._s(item.contentTitle))])}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }