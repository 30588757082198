<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]"
  >
    <div>
      <div class="today-goods-container">
        <div class="title" style="font-size: 14px; font-weight: 600">
          推荐商品
          <router-link to="/hotProduct">
            <span class="title-text">
              <span>查看更多<van-icon name="arrow" /> </span>
            </span>
          </router-link>
        </div>
        <div class="today-scroll-box">
          <div class="today-list-container">
            <div
              class="today-item"
              v-for="(item, index) in recommendLists"
              :key="index"
              @click="toProductDetails(item)"
            >
              <img class="today-image" :src="item.picUrl" alt="img" />
              <div class="title-name">{{ item.productName }}</div>
              <div class="money">￥{{ item.price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      recommendLists: [],
      hotLists: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
    }),
  },
  created() {
    //初始化
    this.loadData();
    console.log("===============================");
  },
  methods: {
    loadData() {
      let data = { offset: 0, limit: 10 };

      //前台获取推荐产品列
      this.$store.dispatch("index/queryRecommendList", data).then(() => {
        this.recommendLists = this.recommendList;
      });

      //前台获取热卖产品列表
      this.$store.dispatch("index/queryHotList", data).then(() => {
        this.hotLists = this.hotList;
      });
    },

    //跳转商品详情界面
    toProductDetails(v) {

      this.$router.push(`/product_detail/${v.id}`);

    },

    /**
     * 跳转到热门商品列表页面
     */
    tohotProduct() {
      this.$router.push("/hotProduct");
    },
  },
};
</script>

<style scoped>
.today-goods-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 0.3125rem;
  border-radius: 6px;
  position: relative;
  gap: 0.625rem;
  background: #fff;
  padding: 0.625rem;
  box-sizing: border-box;
}

.today-goods-container .title-img {
  width: 4.375rem;
  height: 1.25rem;
  transform: translate(-0.625rem);
}

.today-goods-container .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.today-goods-container .title .title-text {
  font-size: 0.8rem;
  font-weight: 400;
  color: #999;
}

.today-goods-container .title .title-text:active {
  color: #fc3510;
}

.today-goods-container .today-scroll-box {
  width: 100%;
  overflow: auto;
}

.today-goods-container .today-scroll-box .today-list-container {
  width: auto;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.today-goods-container .today-scroll-box .today-list-container .today-item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5rem;
  border-radius: 0.3125rem;
}

.today-goods-container
  .today-scroll-box
  .today-list-container
  .today-item
  .today-image {
  width: 100%;
  height: 5rem;
  padding: 0;
  display: block;
  border-radius: 0.3125rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.today-goods-container
  .today-scroll-box
  .today-list-container
  .today-item
  .title-name {
  margin-top: 0.15625rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: #9b9fa8;
  font-size: 12px;
  text-align: center;

  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 4px;
  width: 100%;
  overflow: hidden;
  color: #9b9fa8;
  text-align: center;
}

.today-goods-container
  .today-scroll-box
  .today-list-container
  .today-item
  .money {
  width: 100%;
  text-align: center;
  color: #f84d43;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 4px;
}
</style>
