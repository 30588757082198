<template>
  <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
    },
  ]">
    <div class="banner_con">
      <van-swipe :show-indicators="false" class="guanggao-swipe" :autoplay="3000" indicator-color="white"
        @change="onSwipeChange">
        <van-swipe-item v-for="(item, index) in module.list" :key="index">
          <a :href="item.link" target="_blank">
            <img :src="item.imageUrl" />
          </a>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
};
</script>
<style>
.guanggao-swipe img {
  /* 轮播图图片样式 */
  width: 100%;
  height: auto;
  display: block;
}
</style>
