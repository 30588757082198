<template>
  <div class="app-container">
    <el-card shadow="never">


      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <el-form layout="inline">
          <el-row :gutter="20">

            <el-col :md="6" :sm="24">
              <el-form-item>
                <el-input v-model="queryParam.username" placeholder="请输入用户账号"></el-input>
              </el-form-item>
            </el-col>


            <el-col :md="6" :sm="24">
              <el-button type="primary" size="medium" @click="handleQuery">
                <i class="el-icon-zoom-in"></i>
                查询
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>


      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="userId" label="用户ID" align="center">
        </el-table-column>
        <el-table-column prop="username" label="用户名" align="center">
        </el-table-column>

        <el-table-column prop="levelName" label="用户等级" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="toUpLevel(scope.row)">
              {{ scope.row.levelName }}
            </el-button>
          </template>
        </el-table-column>

        <el-table-column prop="balance" label="用户余额" align="center">
        </el-table-column>

        <el-table-column prop="totalConsumeAmount" label="累计消费" align="center">
        </el-table-column>

        <el-table-column prop="loginLocation" label="登录地址" align="center">
        </el-table-column>

        <el-table-column prop="datelineRegisterReadable" label="注册时间" align="center">
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="float: right; margin: 16px 0">
        <pagination :total="total" :current-page="queryParam.pageNum" :page-size="queryParam.pageSize"
          @currentChange="handleCurrentChange" @editPagesizes="editPageSizes" />
      </div>
    </el-card>


    <!-- 提升等级 -->
    <el-dialog :title="upLevelTitle" destroy-on-close append-to-body :visible.sync="upLevelVisible">

      <el-form label-position="top">
        <el-form-item label="当前等级">
          <el-input :value="currentLevel" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="提升等级">

          <el-select v-model="levelForm.levelNum" placeholder="请选择">
            <el-option v-for="(item, i) in levelList" :key="i" :label="item.levelName" :value="item.levelNum">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>

      <div style="text-align: center;">
        <el-button type="primary" @click="submitLevel()">
          确认升级
        </el-button>
        <el-button @click="upLevelVisible = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { getUserList, queryLowerLevelList, vipUpUserLevel } from "@/api/vip";
import { getToken } from "@/utils/auth";


export default {
  data() {
    return {
      levelList: [],

      //当前等级
      currentLevel: undefined,

      upLevelTitle: undefined,
      upLevelVisible: false,
      tableData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        username:undefined
      },

      levelForm: {
        userId: undefined,
        levelNum: undefined,
      }
    };
  },
  created() {
    this.verifyIsLogin();

    //查询平台等级
    this.getLowerLevelList()
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.initData();
      }
    },

    initData() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        getUserList(this.queryParam)
          .then((res) => {
            //主站
            this.tableData = res.result.list;
            this.total = res.result.total;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 加载平台用户等级
     */
    getLowerLevelList() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        queryLowerLevelList({})
          .then((res) => {
            //主站
            this.levelList = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 提升等级
     */
    toUpLevel(row) {

      this.upLevelTitle = "修改用户:" + row.userId + "等级";

      this.currentLevel = row.levelName;

      this.levelForm.userId = row.userId;

      this.upLevelVisible = true;
    },


    /**
     * 确认提升等级
     */
    submitLevel() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        vipUpUserLevel(this.levelForm)
          .then((res) => {
            if (res.status == 0) {
              this.initData();
              this.upLevelVisible = false;

              this.$notify({
                title: "提示信息",
                message: "修改成功",
                type: "success",
                duration: 2000,
              });
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },


    handleQuery() {
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.initData();
    },


    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },

    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },
  },
};
</script>
