<template>
  <div :style="!module.base.m ? 'margin-top:0' : ''">
    <div class="banner_con">
      <div :style="moduleBgStyle">
        <van-search
          @click="toSearch"
          shape="round"
          :background="module.style.bgcolor"
          placeholder="请输入搜索关键词"
        />
        <!-- 轮播图 -->
        <van-swipe
          class="banner_2"
          :autoplay="3000"
          v-if="module.list.length > 0"
        >
          <van-swipe-item v-for="(item, index) in module.list" :key="index">
            <!-- 轮播图内容 -->
            <img
              :src="item.imageUrl"
              style="width: 100%; padding: 0px 10px 10px 10px"
            />
          </van-swipe-item>
        </van-swipe>

        <template v-else>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 200px;
              text-align: center;
              border: 2px dashed #ccc;
            "
          >
            请点击上传轮播图
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  computed: {
    moduleBgStyle() {
      return {
        background: `linear-gradient(to top,  ${this.module.style.bg2color} ,${this.module.style.bgcolor} 50% )`,
      };
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.banner_2 {
  color: #fff;
  font-size: 20px;
  /* line-height: 150px; */
  text-align: center;
}
</style>
