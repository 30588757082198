<template>
  <div class="page" :style="{ height: pageHeight + 'px' }">
    <div @click="toSearch()">
      <van-sticky>
        <van-search
          placeholder="请输入搜索关键词"
          shape="round"
          @search="toSearch"
        />
      </van-sticky>
    </div>

    <div class="cate-container">
      <div class="cate-list" ref="categoryListRef">
        <div
          v-for="(item, index) in catesList"
          :key="index"
          :class="{ 'category-item': true, active: activeCategory === index }"
          @click="(e) => handleChangeCategory(index)"
          :ref="handleCategoryRefs"
        >
          <span class="category-name">
            {{ item.categoryName }}
          </span>

          <div class="left-tag"></div>
        </div>
        <div
          class="category-item"
          style="flex: 1; background-color: #fff; margin-right: 6px"
        ></div>
      </div>

      <div
        :class="{ 'content-list': true }"
        @scroll="debounceHandleContentScroll"
        ref="contentListRef"
      >
        <div
          v-for="(item, index) in catesList"
          :key="index"
          class="content-item"
          :ref="handleContentRefs"
        >
          <div class="category-title">
            {{ item.categoryName }}
          </div>

          <div class="cate-content">
            <div
              v-for="(child, index) in item.children"
              :key="index"
              class="cate-content-item"
              @click="toProductList(child)"
            >
              <van-image round class="goods-img" :src="child.prcUrl" />
              {{ child.categoryName }}
            </div>
          </div>
        </div>

        <div style="margin-bottom: 50vh"></div>
      </div>
    </div>

    <!-- <div style="padding-bottom: 25%"></div> -->
    <!-- 导航组件 -->
    <div style="height: 50px">
      <NavModule></NavModule>
    </div>
    <!-- <div style="height: 5rem"></div> -->
  </div>
</template>

<script>
import { nextTick } from "vue";

import { mapGetters, mapState } from "vuex";
import NavModule from "@/pages/mobile/common/navModule.vue";

function debounce(func, delay) {
  let timerId;

  return function (...args) {
    const context = this;

    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export default {
  name: "mcate",
  components: { NavModule },

  data() {
    return {
      searchWord: "",
      activeCategory: 0,
      contentRefs: [],
      contentOffsetTops: [],
      categoryRefs: [],
      categoryOffsetTops: [],
      catesList: [],
      pageHeight: undefined,
    };
  },

  activated() {
    let contentScrollTop = Number(localStorage.getItem("contentScrollTop"));
    contentScrollTop = Number.isNaN(contentScrollTop) ? 0 : contentScrollTop;

    if (contentScrollTop) {
      this.$refs.contentListRef.scrollTop = contentScrollTop;
    }

    this.pageHeight = window.innerHeight;
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      bannerList: (state) => state.index.bannerList,
      newList: (state) => state.index.newList,
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
      indexBrandList: (state) => state.index.indexBrandList,
    }),
  },
  created() {
    this.queryCatesList();
  },
  methods: {
    /**
     * 查询商品分类
     */
    queryCatesList() {
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list.filter(
          (item) => item.children?.length
        );
      });
    },

    // 跳转到详情页面
    toProductList(value) {
      this.$router.push({
        path: "product_list",
        query: { categoryId: value.id, categoryName: value.categoryName },
      });
    },

    // 跳转到搜索页面
    toSearch() {
      this.$router.push("/search");
    },

    handleChangeCategory(index) {
      this.activeCategory = index;
      this.debounceHandleCategoryListScroll(index);
      this.$refs.contentListRef.scrollTo({
        top: this.contentOffsetTops[index] - 80,
        behavior: "smooth",
      });
    },

    handleScroll(e) {
      const scrollTop = e.target.scrollTop;

      localStorage.setItem("contentScrollTop", scrollTop);

      for (let i = this.contentOffsetTops.length - 1; i >= 0; i--) {
        if (scrollTop >= this.contentOffsetTops[i] - 150) {
          this.activeCategory = i;
          this.debounceHandleCategoryListScroll(i);
          break;
        }
      }
    },

    handleCategoryListScroll(index) {
      this.$refs.categoryListRef.scrollTo({
        top:
          Math.min(Math.max(0, index - 5), this.categoryRefs.length - 6) * 64,
        behavior: "smooth",
      });
    },

    debounceHandleCategoryListScroll: debounce(function (e) {
      this.handleCategoryListScroll(e);
    }, 100),

    debounceHandleContentScroll: debounce(function (e) {
      this.handleScroll(e);
    }, 100),

    handleContentRefs(e) {
      this.contentRefs.push(e);
      nextTick(() => {
        this.contentOffsetTops = this.contentRefs.map((item) => item.offsetTop);
      });
    },

    handleCategoryRefs(e) {
      this.categoryRefs.push(e);
      nextTick(() => {
        this.categoryOffsetTops = this.categoryRefs.map(
          (item) => item.offsetTop
        );
      });
    },
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
}

.input-container {
  position: relative;
  margin: 12px 6px;
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  border: none;
  outline: none;
  border-radius: 16px;
  padding-left: 36px;
  padding-right: 15px;
  background-color: #f0f0f09c;
  color: #666;
  font-size: 15px;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.input-icon svg {
  width: 16px;
  height: 16px;
}

.input::placeholder {
  color: #999;
}

.cate-container {
  display: flex;
  flex: 1;
  height: calc(100% - 104px);
}

.cate-list {
  flex-shrink: 0;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}

.content-list {
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  flex: 1;
}

.category-item {
  flex-shrink: 0;
  position: relative;
  margin-right: 6px;
  height: 64px;
  display: flex;
  align-items: center;
  background: #fff;
  color: #9b9fa8;
}

.category-item.active {
  font-size: 14px;
  color: #000;
  background: transparent;
}

.category-item .left-tag {
  position: absolute;
  height: 18px;
  width: 4px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
}

.active.category-item .left-tag {
  background: red;
}

.category-name {
  max-width: 70px;
  margin-left: 12px;
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-list {
  padding: 20px 12px;
  background-color: rgb(255, 255, 255);
}

.content-item {
  margin-bottom: 36px;
}

.cate-content {
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.cate-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.78125rem 0;
  gap: 0.34375rem;
  /* display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3px; */
  font-size: 12px;
  color: #999;
}

/* .cate-content-item img {
  width: 64px;
  height: 64px;
} */

.cate-content-item .goods-img {
  width: 42px;
  height: 42px;
  /* width: 100%;
  height: 100%; */
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
  border-radius: 20%;
}
</style>
