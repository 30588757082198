<template>
  <div :style="!module.base.m ? 'margin-top:0' : ''">
    <div class="banner_con">
      <div :style="moduleBgStyle">


        <div class="searchBox" @click="toSearch()">
          <!-- 默认 -->
          <template v-if="!showSearchBar">
            <van-search @click="toSearch" readonly shape="round" :background="module.style.bgcolor"
              placeholder="请输入搜索关键词" />
          </template>

          <!-- 下拉展示 -->
          <template v-else>
            <van-sticky>
              <van-search @click="toSearch" readonly shape="round" placeholder="请输入搜索关键词" background="#FFFFFF" />
            </van-sticky>
          </template>
        </div>


        <div style="padding: 5px 15px;" v-if="module.list.length > 0">
          <!-- 轮播图 -->
          <van-swipe class="banner_2" :autoplay="3000">
            <van-swipe-item v-for="(item, index) in module.list" :key="index">
              <!-- <el-image
            :src="item.imageUrl" style="width: 100%; padding: 0px 10px 5px 10px;border-radius: 5px;" /> -->


              <a :href="item.link" target="_blank">
                <el-image :src="item.imageUrl" style="width: 100%; border-radius: 4px">
                </el-image>
              </a>
            </van-swipe-item>
          </van-swipe>
        </div>

        <template v-else>
          <div
            style="display: flex; justify-content: center; align-items: center; height: 200px; text-align: center; border: 2px dashed #ccc;">
            请点击上传轮播图
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],
  computed: {
    moduleBgStyle() {
      return {
        background: `linear-gradient(to top,  ${this.module.style.bg2color
          } ,${this.module.style.bgcolor} 50% )`
      };
    }
  },
  data() {
    return {
      showSearchBar: false,
    };
  },
  mounted() {

    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 处理页面滚动事件
    handleScroll() {
      // 获取页面滚动的垂直距离
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // 根据滚动距离来控制是否显示顶部搜索栏
      // 这里假设超过 200px 就显示搜索栏，你可以根据实际需求调整阈值
      if (scrollTop > 200) {
        this.showSearchBar = true;
      } else {
        this.showSearchBar = false;
      }
    },



    toSearch() {
      this.$router.push("/search");
    },
  }
};
</script>

<style>
/* .banner_2 {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
} */
</style>
