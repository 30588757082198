var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
    !_vm.module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
    }
  ])},[_c('div',{staticClass:"choice_view between"},[_c('van-list',{attrs:{"finished-text":"没有更多了"}},[_c('div',{staticClass:"gds-list"},[_c('van-row',{attrs:{"gutter":"10"}},_vm._l((_vm.listData),function(value,key,index){return _c('van-col',{key:index,attrs:{"span":"12"}},[_c('div',{staticStyle:{"background":"#fff","padding-bottom":"10px","margin-bottom":"10px"}},[_c('span',{staticClass:"pic-wrap"},[_c('div',{staticClass:"nut-image",staticStyle:{"height":"180px","padding":"5px"}},[_c('van-image',{staticClass:"nut-img",attrs:{"src":value.productImg}})],1)]),_c('div',{staticStyle:{"padding":"0px 10px 10px 10px"}},[_c('span',{staticClass:"tit"},[_vm._v(" "+_vm._s(value.productName)+" ")])]),_c('div',{staticClass:"dx2-goods-price"},[_c('div',[_c('div',{staticClass:"nut-price",staticStyle:{"font-weight":"bold"}},[_c('div',{staticClass:"nut-price--symbol nut-price--symbol-normal"},[_vm._v(" ¥ ")]),_c('div',{staticClass:"nut-price--normal"},[_vm._v(" "+_vm._s(value.productPrice)+" ")])])]),_c('div',[_c('van-tag',{attrs:{"type":"primary"}},[_vm._v("直冲")])],1)])])])}),1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }