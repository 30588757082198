<template>
  <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
    }
  ]">
    <van-notice-bar left-icon="volume-o" scrollable :color="module.style.color" :background="module.style.bgcolor">
      欢迎使用换换云系统
    </van-notice-bar>
  </div>
</template>

<script>
export default {
  props: ["module"],
  components: {},

  data() {
    return {
      nav: []
    };
  },
  computed: {}
};
</script>

<style>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /* line-height: 150px; */
  text-align: center;
  /* background-color: #39a9ed; */
}
</style>
