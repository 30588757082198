<template>
  <div>

    <div @click="toSearch()">

        <van-search readonly shape="round" :placeholder="module.style.text" :background="module.style.bgcolor" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["module"],
  methods: {
    toSearch() {
      this.$router.push("/search");
    },
  },

};
</script>