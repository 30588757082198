var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-common",style:([
  !_vm.module.base.m ? { 'margin-top': '0' } : {},
  {
    padding:
      '0px ' + _vm.module.base.margins + 'px 0px ' + _vm.module.base.margins + 'px'
  },
])},[_c('div',{staticClass:"slide_view"},[_c('van-tabs',{attrs:{"scrollspy":"","title-active-color":"#1e1e1e"}},_vm._l((_vm.catesList),function(value,key,index){return _c('van-tab',{key:index,attrs:{"title":value.categoryName}},[_c('div',{staticClass:"recommend_view"},[_c('div',[_c('div',{staticClass:"recommend_title"},[_vm._v(" "+_vm._s(value.categoryName)+" ")]),_c('div',{staticClass:"recommend_con rows"},_vm._l((value.children),function(goods,key,index){return _c('div',{key:index,staticClass:"index_1_item column",on:{"click":function($event){return _vm.toProductList(goods)}}},[_c('van-image',{attrs:{"width":"40","height":"40","src":goods.prcUrl}}),_c('div',{staticStyle:{"margin-top":"5px"}},[_vm._v(_vm._s(goods.categoryName))])],1)}),0)])])])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }