<template>
  <div>
    <!-- <Header /> -->

    <div class="container">
      <div
        class="public-crumbs"
        style="
          margin-top: 20px;
          margin-bottom: 20px;
          padding: 20px;
          background: #fff;
          border-radius: 8px;
          /* display: flex; */
          align-items: center;
          box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
        "
      >
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            ><a @click="toIndex()">首页</a></el-breadcrumb-item
          >
          <el-breadcrumb-item>商品列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <van-list
        v-model="loadingState"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadMore"
      >
        <div class="public-shoplist">
          <div v-if="goodsList.length === 0" style="margin: auto">
            <el-empty description="暂无数据"></el-empty>
          </div>

          <div
            class="item"
            v-for="(v, k, i) in goodsList"
            :key="i"
            :title="v.name"
            @click="toProductDetails(v)"
          >
            <a>
              <div class="pic">
                <img class="lazy" :src="v.picUrl" style="display: inline" />
              </div>
              <div class="title">
                {{ v.productName }}
              </div>
              <div class="money">￥{{ v.price }}</div>
              <div class="label">
                <div class="mode">{{ v.productTypeText }}</div>
                <div class="stock">{{ v.id }}</div>
              </div>
            </a>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
// import Header from "../../layout/header";
import { mapGetters, mapState } from "vuex";
import "@/assets/pc/css/style.css";
import "@/assets/pc/css/common.css";
export default {
  name: "HelloWorld",
  data() {
    return {
      value1: 0,
      value2: "a",
      goodsLabelList: [],
      goodsList: [],
      queryParam: {
        goodsId: undefined,
      },
      queryGoodsRelationSpecsParam: {
        goodsId: undefined,
      },
      queryParamName: {
        specsName: undefined,
      },
      goodsName: "",
      goodsStatus: false,
      pageNum: 1,
      pageSize: 10,
      loadingState: false,
      finished: false,
    };
  },
  components: {
    // Header,
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      productList: (state) => state.index.productList,
    }),
  },
  created() {
    document.title = "商品列表";
    //获取分类标签
    // this.getList();
    //获取获取商品列表
    this.getProductList();
  },
  watch: {
    $route(to, from) {
      if (to.query.goodsName != from.query.goodsName) {
        this.goodsName = to.query.goodsName; //变量赋值
        //初始化参数
        this.goodsList = [];
        this.pageNum = 1;
        this.pageSize = 10;
        this.finished = false;

        this.getProductList2(); //重新加载数据
      }
    },
  },
  methods: {
    /**
     * 加载更多
     */
    loadMore() {
      this.pageNum = this.pageNum + 1;
      this.loadingState = true;
      this.getProductList();
    },

    //查询商品列表
    getProductList() {
      let data = {
        brandId: this.$route.query.brandId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchKeyword: this.$route.query.goodsName,
      };
      console.log(data);
      this.$store.dispatch("index/queryProductList", data).then(() => {
        this.goodsList = this.goodsList.concat(this.productList.productList);
        if (this.productList.productList.length == 0) {
          this.finished = true;
        }
        this.loadingState = false;
      });
    },

    //查询商品列表
    getProductList2() {
      //初始化参数
      let data = {
        brandId: this.$route.query.brandId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchKeyword: this.goodsName,
      };
      console.log(data);
      this.$store.dispatch("index/queryProductList", data).then(() => {
        this.goodsList = this.productList.productList;
        // this.$forceUpdate();
      });
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/pgoodsDetail/${v.id}`);
    },

    //==================================

    // 查询商品列表
    searchGoodsList() {
      // this.goodsStatus = false
      // if (this.goodsName == '') {
      //     this.goodsName = this.$route.query.goodsName
      // }
      // this.queryParamName.specsName = this.goodsName
      // querySpecsList(this.queryParamName).then((response) => {
      //     if (response.rows.length != 0) {
      //         this.productList = response.rows
      //     } else {
      //         this.goodsStatus = true
      //         this.productList=[]
      //     }
      //     })
      this.$router.push("/search");
    },

    //获取分类标签
    getList() {
      //   queryProductDetails(this.$route.query.goodsId).then((response) => {
      //     this.goodsLabelList = response.goods;
      //   });
    },

    //跳转到首页
    toIndex() {
      this.$router.push("/index");
    },
  },
};
</script>
