<template>
  <div>
    <!-- <el-card shadow="never"> -->
    <div v-for="(item, index) in orderParams" :key="index">
      <el-form-item :label="item.name" v-if="item.type === '1'">
        <el-input v-model="item.value" :placeholder="item.desc"> </el-input>
      </el-form-item>

      <el-form-item :label="item.name" v-else-if="item.type === '2'">
        <el-input v-model="item.value" :placeholder="item.desc">
          <template slot="append">
            <el-button type="primary" @click="extractLink(item.value, index)"
              >提取链接</el-button
            >
          </template>
        </el-input>
      </el-form-item>

      <!-- 图片上传 -->
      <el-form-item :label="item.name" v-else-if="item.type === '4'">
        <div>
          <el-upload
            action="/api/upload/public_pic"
            :headers="headers"
            :on-success="UploadOnSuccess(item, index)"
            :before-upload="beforeAvatarUpload"
            :limit="100"
            :show-file-list="false"
          >
            <img
              v-if="item.value"
              :src="item.value"
              style="width: 80px; border-radius: 5px"
            />
            <el-button v-else size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </el-form-item>

      <el-form-item :label="item.name" v-else>
        <el-input v-model="item.value" :placeholder="item.desc"> </el-input>
      </el-form-item>
    </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
import { getToken, getUserId } from "@/utils/auth";

export default {
  headers: {
    token: getToken(),
    userId: getUserId(),
  },
  props: ["orderParams"],
  data() {
    return {
      uploadfileUrl: undefined,
      orderParamList: [],
    };
  },

  methods: {
    extractLink(value, index) {
      if (value === "") {
        this.$message({
          message: "请输入参数",
          type: "error",
        });
        return;
      }
      this.orderParams[index].value = this.$extractLink(value);
    },

    /**
     * 图片上传
     */
    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    },

    /**
     * 上传成功返回参数
     */
    UploadOnSuccess(res, index) {
      return (response) => {
        this.orderParams[index].value = response.url;

        this.orderParams[index].uploadfileId = response.id;
      };
    },
  },
};
</script>
<style scoped></style>
