<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
      { 'border-radius': module.base.round + 'px' },
    ]"
  >
    <div :style="[{ 'border-radius': module.base.round + 'px' }]">
      <!-- 导航 -->
      <div class="classify_view">
        <van-swipe :loop="false" indicator-color="#2196F3">
          <van-swipe-item
            v-for="(group, index) in groupedNavLists"
            :key="index"
          >
            <van-grid :column-num="4" :border="false" class="no-border-grid">
              <van-grid-item
                v-for="item in group"
                :key="item.id"
                @click="toProductList(item)"
              >
                <van-image
                  round
                  :src="item.prcUrl"
                  style="width: 40px; height: 40px; border-radius: 5px"
                />

                <div style="padding-top: 5px">
                  {{ item.categoryName }}
                </div>
              </van-grid-item>
            </van-grid>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      navLists: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      indexBrandList: (state) => state.index.indexBrandList,
    }),

    groupedNavLists() {
      const groups = [];
      const groupSize = 8;
      for (let i = 0; i < this.navLists.length; i += groupSize) {
        groups.push(this.navLists.slice(i, i + groupSize));
      }
      return groups;
    },
  },
  created() {
    //查询首页Nav导航商品
    this.getNavList();
  },

  methods: {
    //查询首页Nav导航商品
    getNavList() {
      let data = { offset: 0, limit: 10 };

      this.$store.dispatch("index/queryIndexBrandList", data).then(() => {
        this.navLists = this.indexBrandList.list;
        // this.$forceUpdate();
      });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      console.log(value);
      this.$router.push({
        path: "product_list",
        query: { categoryId: value.id, categoryName: value.categoryName },
      });
    },
  },
};
</script>

<style>
.classify_view {
  /* width: 347px; */
  /* height: 334rpx; */
  background-color: #fff;
}
</style>
