<template>
  <div :style="!module.base.m ? 'margin-top:0' : ''">
    <div class="bannerComb on" configdata="[object Object]">
      <div class="bgImg">
        <img :src="currentBackground" />
      </div>
      <div class="searchBox" @click="toSearch()">
        <!-- 默认 -->
        <template v-if="!showSearchBar">
          <van-search
            readonly
            shape="round"
            placeholder="请输入搜索关键词"
            background="transparent"
          />
        </template>

        <!-- 下拉展示 -->
        <template v-else>
          <van-sticky>
            <van-search
            
              disabled
              shape="round"
              placeholder="请输入搜索关键词"
              background="#FFFFFF"
            />
          </van-sticky>
        </template>
      </div>
      <div class="banner">
        <van-swipe
          :autoplay="3000"
          indicator-color="white"
          @change="onSwipeChange"
          v-if="module.list.length > 0"
        >
          <van-swipe-item v-for="(item, index) in module.list" :key="index">
            <a :href="item.link" target="_blank">
              <el-image
                :src="item.imageUrl"
                style="width: 100%; border-radius: 10px"
              >
              </el-image>
            </a>
          </van-swipe-item>
        </van-swipe>

        <template v-else>
          <el-image style="height: 160px; width: 100%; border-radius: 10px">
          </el-image>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["module"],

  data() {
    return {
      // 默认背景
      currentBackground: "",

      showSearchBar: false,
    };
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    // 初始化时设置第一张轮播图的背景
    if (this.module.list.length > 0) {
      this.currentBackground = this.module.list[0].imageUrl;
    }

    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 处理页面滚动事件
    handleScroll() {
      // 获取页面滚动的垂直距离
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // 根据滚动距离来控制是否显示顶部搜索栏
      // 这里假设超过 200px 就显示搜索栏，你可以根据实际需求调整阈值
      if (scrollTop > 200) {
        this.showSearchBar = true;
      } else {
        this.showSearchBar = false;
      }
    },

    onSwipeChange(index) {
      // 当轮播图切换时，更新背景
      this.currentBackground = this.module.list[index].imageUrl;
    },

    toSearch() {
      this.$router.push("/search");
    },
  },
};
</script>

<style>
.fixed-search {
  background-color: red;
}
.bannerComb {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 3px;
}

.bannerComb .bgImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  filter: blur(0);
  overflow: hidden;
}

.bannerComb .searchBox {
  position: relative;
  z-index: 1;
}

.bannerComb .banner {
  padding: 0px 15px;
  /* height: 160px; */
  position: relative;
  z-index: 1;
  border-radius: 6px;
  margin: 0 auto;
}

.bannerComb .banner img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.bannerComb .bgImg img {
  width: 100%;
  height: 100%;
  filter: blur(15px);
  transform: scale(1.5);
}
</style>
