<template>
  <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
    },
  ]">
    <div>
      <van-notice-bar :color="module.style.color" :background="module.style.bgcolor" style="border-radius: 5px"
        left-icon="volume-o" :scrollable="false">
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in listData" :key="index" @click="toNoticeDetails(item)">{{
            item.contentTitle }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
  </div>
</template>
<script>
import { queryNewsRecommendList } from "@/api/news";

export default {
  props: ["module"],
  data() {
    return {
      queryParam: {},
      listData: [],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    /**
     * 查询首页公告
     */
    initData() {
      queryNewsRecommendList(this.queryParam).then((response) => {
        if (response.status == 0) {
          this.listData = response.result.contentList;
        }
      });
    },

    /**
     * 展示公告详情
     */
    toNoticeDetails(row) {
      this.$router.push({ name: "mNoticeDetails", params: { id: row.id } });
    },
  },
};
</script>

<style>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  /* line-height: 150px; */
  text-align: center;
  /* background-color: #39a9ed; */
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}
</style>
