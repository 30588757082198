<template>
 <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px'
    },
  ]">    <div>
      <div class="gds-list3">
        <van-tabs :offset-top="50" @ :ellipsis="false">
          <van-tab :title="item.categoryName" v-for="item in catesList" :key="item.id" :name="item.id">
            <div>
              <div style="display: flex; flex: 1; height: calc(100%)">
                <div style="
                    background-color: #ffffff;
                    font-size: 14px;
                    margin-right: 6px;
                  ">
                  <van-sidebar v-model="activeKey" @change="onChange">
                    <van-sidebar-item style="background-color: #ffffff" :title="value.categoryName"
                      v-for="value in item.children" :key="value.id" :name="value.id" @click="toSecondPCate(value)" />
                  </van-sidebar>
                </div>

                <div style="
                    box-sizing: border-box;
                    height: 100%;
                    overflow: auto;
                    flex: 1;
                  ">
                  <van-list>
                    <div>
                      <div>
                        <div class="kind-show goods-list">


                          <div class="goods-content">
                            <div class="goods-item" v-for="(v, k, i) in listData" :key="i" :title="v.productName"
                              @click="toProductDetails(v)">
                              <div class="goods-img">
                                <van-image class="choice_img" :src="v.productImg" />
                              </div>

                              <div class="product_centent">
                                <span class="name">{{ v.productName }}</span>

                                <div class="fun">
                                  <span class="money">
                                    <span>
                                      <span class="now-money">
                                        <span style="font-size: 16px">￥{{ v.productPrice }}</span>
                                      </span>

                                    </span>
                                  </span>

                                  <!-- 按钮 -->
                                  <!-- <div style="padding: 0px 10px 10px 0px">
                              <van-button
                                color="linear-gradient(to right, #1989FA, #1989FA)"
                                type="success"
                                size="mini"
                                >立即购买</van-button
                              >
                            </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </van-list>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["module"],
  data() {
    return {
      catesList: [
        {
          categoryName: "影视会员",
          children: [
            {
              categoryName: "PP视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "哔哩哔哩",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1642616483.jpg"
            },
            {
              categoryName: "芒果TV",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1693224309.jpg"
            },
            {
              categoryName: "优酷视频",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1603739972.jpg"
            }
          ]
        },
        {
          categoryName: "音频会员",
          children: [
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            },
            {
              categoryName: "喜马拉雅",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1459846552.jpg"
            },
            {
              categoryName: "酷我音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1889239183.jpg"
            },
            {
              categoryName: "酷狗音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1677053753.jpg"
            },
            {
              categoryName: "网易云音乐",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1604095683.jpg"
            }
          ]
        },
        {
          categoryName: "美食餐饮",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            },
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        },
        {
          categoryName: "商务办公",
          children: [
            {
              categoryName: "爱奇艺",
              prcUrl: "http://img.huanhuanle.cn/2023-07-24-1792749402.jpg"
            }
          ]
        }
      ],
      listData: [
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        },
        {
          productImg: "http://img.huanhuanle.cn/2023-10-08-1665323248.jpg",
          productName: "喜马拉雅VIP会员【7天】官方直冲",
          productPrice: "2.5"
        }
      ],
      pageHeight: undefined,
      activeKey: undefined,
      showOverlay: true,
      goodsLabelList: [],
      goodsList: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryId: undefined,
        searchKeyword: undefined,
        queryCondition: null,
      },
      goodsName: "",
      goodsStatus: false,
      pageNum: 1,
      pageSize: 10,
      loadingState: false,
      finished: false,
      isFetching: false, // 是否有正在进行中的请求
    };
  },

  created() {


  },
  methods: {


  },
};
</script>
<style>
.goods3-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
}

.goods3-content .goods-item {
  width: 100%;
  /* height: 6.25rem; */
  background: #ffffff;
  border-radius: 0.3125rem;
  /* display: flex; */
  /* align-items: center; */
  justify-content: flex-start;
  /* gap: 0.625rem; */
  padding: 10px;
}

.goods3-content .goods-item .goods-img {
  width: 5rem;
  /* height: 5.625rem; */
  border-radius: 5px;
  overflow: hidden;
}

.goods3-content .goods-item .product_centent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 10px;
  margin-left: 10px;
}

.goods3-content .goods-item .product_centent .name {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 14.375rem; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 40px;
}

.goods3-content .goods-item .product_centent .fun {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.goods3-content .goods-item .product_centent .fun .money {
  font-weight: 500;
  color: #f84d43;
  font-size: 1.125rem;
}

.goods3-content .goods-item .product_centent .fun .money .default-money {
  font-weight: 400;
  color: #d2d5dd;
  font-size: 0.6875rem;
  text-decoration: line-through;
  margin-left: 0.53125rem;
}

.goods3-content .goods-item .product_centent .fun .buy-btn {
  width: 4.0625rem;
  height: 1.5625rem;
  background: linear-gradient(90deg, #fe8a56 0%, #ff504b 100%);
  border-radius: 0.78125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}

/* 组件样式 */
.m-menu-class .el-submenu__title {
  padding-left: 10px !important;
}

.m-menu-class .el-submenu__icon-arrow {
  right: 10px;
}

.m-menu-class .el-submenu .el-menu-item {
  min-width: 0px;
}

.gds-list3 .van-tab--active {
  border-radius: 50px;
  background-color: #eb6253;
  font-size: 14px;
  color: #fff;
  margin: 8px 0px;
}

.gds-list3 .van-tabs__line {
  display: none;
}

.gds-list3 .van-tabs__nav {
  /* background-color: transparent; */
}

/* .gds-list3 .van-tabs__content {
  margin-top: 10px;
} */

.gds-list3 .van-tabs__nav--line.van-tabs__nav--complete {
  padding-right: 0px;
  padding-left: 0px;
}

.gds-list3 .van-grid-item__text {
  font-size: 14px;
}
</style>
