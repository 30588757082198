<template>
  <div>
    <div
      v-if="!dialogVisible"
      style="
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 40px;
        text-align: center;
      "
    >
      <el-card shadow="never">
        <H2 style="text-align: center; padding: 40px">代理查价系统</H2>
        <div class="levelPrice-container">
          <el-form label-position="top">
            <el-form-item>
              <el-input
                v-model="dataForm.password"
                placeholder="请输入查询密码"
                style="width: 320px"
              ></el-input>
            </el-form-item>

            <el-form-item style="text-align: center">
              <el-button type="primary" @click="sumitQuery" size="medium"
                >立即查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>

    <!-- 输入密码后展示 -->
    <div v-else>
      <el-card
        style="width: 1300px; margin: 0 auto; margin-top: 20px"
        shadow="never"
      >
        <el-card shadow="never">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="商品编号">
              <el-input
                size="small"
                v-model="queryParam.productId"
                placeholder="请输入商品编号"
              ></el-input>
            </el-form-item>

            <el-form-item label="商品名称">
              <el-input
                size="small"
                v-model="queryParam.productName"
                placeholder="请输入商品名称"
              ></el-input>
            </el-form-item>

            <el-form-item label="商品分类">
              <el-cascader
                v-model="queryParam.categoryId"
                :options="catesList"
                :props="TagProps"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit" size="small">
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>

        <van-list
          v-model="loadingState"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadMore"
        >
          <el-table :data="tableData" :span-method="objectSpanMethod" border>
            <el-table-column
              prop="categoryId"
              label="商品分类"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.categoryImg"
                ></el-image>

                <div style="font-size: 12px">{{ scope.row.categoryName }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="productId" label="商品编号" align="center">
            </el-table-column>

            <el-table-column prop="productName" label="商品名称" width="300">
              <!-- <template slot-scope="scope">
                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 5px;
                  ">
                  <el-image style="width: 50px" :src="scope.row.productImg"></el-image>

                  <span style="font-size: 12px">{{
                    scope.row.productName
                  }}</span>
                </div>
              </template> -->
            </el-table-column>

            <el-table-column
              prop="productTypeText"
              label="商品类型"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  :color="scope.row.productTypeColor"
                  effect="dark"
                >
                  {{ scope.row.productTypeText }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
              v-for="column in tableColumns"
              :key="column.prop"
              :prop="column.prop"
              :label="column.label"
              align="center"
            >
            </el-table-column>
          </el-table>
        </van-list>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  getLevelPriceProductList,
  confirmPricePassword,
} from "@/api/product";

import { getLevelList } from "@/api/user";


import { queryProducCategorytList } from "@/api/index";

export default {
  computed: {
    tableColumns() {
      const columns = [];
      for (let i = 0; i < this.levelList.length; i++) {
        let levelName = this.levelList[i].levelName;
        let value = this.levelList[i].value;
        columns.push({
          prop: `levelPrice${value}`, // 假设所有列都显示相同的数据属性
          label: levelName, // 动态生成列标题
        });
      }
      return columns;
    },
  },
  data() {
    return {
      TagProps: {
        value: "id",
        label: "categoryName",
        children: "children",
      },
      catesList: [],
      dataForm: {
        password: undefined,
      },
      dialogVisible: false,
      levelList: [],
      tableData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 30,
        productId: undefined,
        productName: undefined,
        categoryId: undefined,
        categoryPid: undefined,
      },

      loadingState: false,
      finished: false,
      isFetching: false, // 是否有正在进行中的请求
    };
  },

  created() {
    if (localStorage.getItem("levelPricePass")) {
      this.dialogVisible = true;

      //查询分类列表
      this.queryCategorytList();

      //查询等级
      this.queryMemberLevelList();

      //查询商品
      this.loadData();
    }
  },
  methods: {
    /**
     * 提交查询密码
     */
    sumitQuery() {
      new Promise((resolve, rejust) => {
        confirmPricePassword(this.dataForm)
          .then((res) => {
            if (res.status == 0) {
              this.dialogVisible = true;

              localStorage.setItem("levelPricePass", true);

              //查询分类列表
              this.queryCategorytList();

              //查询等级
              this.queryMemberLevelList();

              //查询商品
              this.loadData();
            }

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 查询商品分类
     */
    queryCategorytList() {
      new Promise((resolve, rejust) => {
        queryProducCategorytList({})
          .then((res) => {
            if (res.status == 0) {
              this.catesList = res.result.list;
            }

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    handleChange(k) {
      this.queryParam.categoryId = k[1];
    },

    /**
     * 查询等级列表
     */
    queryMemberLevelList() {
      new Promise((resolve, rejust) => {
        getLevelList({})
          .then((res) => {
            this.levelList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 加载更多
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.loadData();
    },

    /**
     * 查询等级价格
     */
    loadData() {
      this.isFetching = true;

      new Promise((resolve, rejust) => {
        getLevelPriceProductList(this.queryParam)
          .then((res) => {
            this.tableData = this.tableData.concat(res.result);

            if (res.result.length == 0) {
              this.finished = true;
            }
            this.loadingState = false;
            this.isFetching = false;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 提交查询
     */
    onSubmit() {
      this.tableData = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.loadData();
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (columnIndex === 0) {
        if (
          rowIndex > 0 &&
          row.categoryId === this.tableData[rowIndex - 1].categoryId
        ) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        } else {
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.tableData.length; i++) {
            if (this.tableData[i].categoryId === row.categoryId) {
              rowspan++;
            } else {
              break;
            }
          }
          return {
            rowspan: rowspan,
            colspan: 1,
          };
        }
      }
    },
  },
};
</script>
<style>
.levelPrice-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* 或者设置为你的页面高度 */
  margin: 0;
  /* 确保没有外边距影响居中效果 */
  padding: 20px;
  /* 可选，根据你的需要添加内边距 */
  box-sizing: border-box;
  /* 使用border-box盒模型来包含内边距和边框 */
}
</style>
