<template>
  <div class="app-container">
    <el-card shadow="never">
      <!-- 条件查询 -->
      <div>
        <el-form :inline="true" :model="queryParam" class="demo-form-inline">
          <el-form-item>
            <el-input
              v-model="queryParam.orderNum"
              placeholder="请输入订单号"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="medium" @click="handleQuery"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <el-table :data="list" border style="width: 100%">
        <el-table-column
          align="center"
          prop="serialNum"
          label="订单号"
          width="280"
        >
        </el-table-column>

        <el-table-column
          prop="orderFormProduct.productName"
          label="商品名称"
          width="300"
          align="center"
        >
        </el-table-column>

        <el-table-column align="center" label="商品类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.orderFormProduct.productTypeText }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="orderFormProduct.productPriceDeal"
          label="商品价格"
        >
        </el-table-column>

        <el-table-column
          align="center"
          prop="orderFormProduct.quantity"
          label="充值数量"
        >
        </el-table-column>

        <el-table-column align="center" prop="amountFinally" label="订单金额">
        </el-table-column>

        <!-- <el-table-column prop="vipProfitAmount" align="center" label="盈利金额">
        </el-table-column> -->

        <el-table-column
          align="center"
          prop="datelineCreateReadable"
          label="下单时间"
          width="200"
        >
        </el-table-column>

        <el-table-column
          align="center"
          prop="userId"
          label="用户ID"
          width="200"
        >
        </el-table-column>

        <el-table-column align="center" prop="statusText" label="订单状态">
          <template slot-scope="scope">
            <el-tag
              :color="scope.row.statusTextColor"
              effect="dark"
              size="small"
            >
              {{ scope.row.statusText }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="deliveryRemark"
          label="订单备注"
          width="120"
        >
        </el-table-column>
        <!-- 
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link type="primary" @click="orderDetails(scope.$index, scope.row)"
            >订单详情</el-link
          >
        </template>
      </el-table-column> -->
      </el-table>

      <div style="float: right; margin: 16px 0">
        <el-pagination
          layout="prev, pager, next"
          :page-size="queryParam.pageSize"
          @current-change="currentChangeHandle"
          :current-page="queryParam.pageNum"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { queryUserOrder } from "@/api/vip";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      list: [],
      total: 0,

      queryParam: {
        pageNum: 1,
        pageSize: 10,
        orderNum: undefined,
      },
    };
  },

  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.load();
      }
    },

    load() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        queryUserOrder(this.queryParam)
          .then((res) => {
            //主站
            this.list = res.result.list;
            this.total = res.result.total;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1;
      this.load();
    },

    //主站商品点击分页
    currentChangeHandle(current, pageSize) {
      console.log(current, pageSize);
      this.queryParam.pageNum = current;
      this.load();
    },
  },
};
</script>
